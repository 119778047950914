.weather-today {
  display: flex;
  flex-direction: column;

  @media (min-width: 901px) {
    flex-direction: row;
  }
}

.weather {
  color: $black;
  font-family: 'Oswald', sans-serif;
  position: relative;

  @media (min-width: 901px) {
    margin: 60px 200px;
  }

  h1 {
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 33px;
  }

  h2 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 33px;
  }

  h3 {
    font-weight: 600;
    margin-bottom: 10px;
  }

  .location {
    font-size: 22px;
    margin: 5px 0 30px;
  }

  .temp {
    font-size: 130px;
    letter-spacing: -7px;
    margin: 0 0 45px;
  }

  .overall {
    margin-bottom: 40px;

    p {
      font-family: 'Playfair Display', serif;
      -webkit-font-smoothing: antialiased;
      font-size: 16px;
    }
  }

  .conditions {
    margin-bottom: 30px;
  }

  &:last-child {
    @media (min-width: 901px) {
      margin: 0;
    }
  }

  .loader-container {
    background-color: $white;
    left: 0;
    height: 75%;
    margin-left: 0;
    top: 20%;
    width: 100%;
  }
}

.weather-divider {
  border-top: 4px solid $black;
  height: 40px;
  margin: 0 auto;
  width: 110px;

  @media (min-width: 901px) {
    height: 20px;
  }
}

.freeze {
  color: #cceaf2;
}

.cold {
  color: #e0e2e2;
}

.cool {
  color: #bfbebb;
}

.mild {
  color: #9f8c7d;
}

.warm {
  color: #e0874a;
}

.hot {
  color: #ffb600;
}

.wind-warning {
  fill: red;
}

.wind-direction {
  margin: 0 auto;
  padding: 10px;
  width: 70px;
}

.weather-weekly-forecast {
  margin-top: 90px;

  h1 {
    color: $black;
    font-family: 'Oswald', sans-serif;
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 33px;
  }
}

.weather-forecast {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: scroll;
  padding-bottom: 50px;

  @media (min-width: 901px) {
    flex-direction: row;
    width: 715px;
  }

  @media (min-width: 1201px) {
    width: 985px;
  }

  @media (min-width: 1501px) {
    width: 1260px;
  }
}

.weather-daily {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  list-style: none;
  font-family: 'Oswald', sans-serif;
  margin: 25px 0 50px;

  @media (min-width: 901px) {
    margin: 25px 100px 0 0;
  }

  .weather-background {
    background-size: cover;
    position: relative;
    height: 231px;
    width: 170px;

    p {
      color: $white;
      position: absolute;
      font-size: 25px;
      letter-spacing: 1px;
    }

    .day {
      left: 7px;
      top: 0;
    }

    .max {
      font-size: 32px;
      font-weight: 900;
      left: 95px;
      top: -3px;
    }

    .max-temp {
      font-size: 73px;
      font-weight: 900;
      letter-spacing: -4px;
      left: 95px;
      top: 25px;
    }

    .min {
      left: 95px;
      top: 111px;
    }

    .min-temp {
      left: 95px;
      top: 141px;
    }
  }

  .main-description {
    color: $grey-dark;
    font-size: 30px;
    margin: 12px 0 6px;
  }

  .description {
    font-family: 'Playfair Display', serif;
    -webkit-font-smoothing: antialiased;
    font-size: 21px;
    margin-bottom: 5px;
  }

  .wind-details {
    align-items: center;
    color: $grey-mid;
    display: flex;
    justify-content: space-between;
    margin-bottom: 7px;
    width: 160px;

    .circle {
      background-color: $grey;
      border-radius: 50%;
      height: 30px;
      width: 30px;

      p {
        color: $white;
        margin-top: 5px;
      }
    }
  }

  .sun-details {
    color: $grey-mid;
    display: flex;
    justify-content: space-between;
    width: 160px;
  }
}
