// colours

$white: #fff;
$black: #000;
$grey-pale: #bbb;
$grey: #999;
$grey-mid: #666;
$grey-dark: #333;
$earth: #01a39e;

// breakpoints 375 501 701 901 1201
