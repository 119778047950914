.homepage {
  .homepage-content {
    align-items: center;
    background: url('../assets/images/steamgrafik-cover.jpg') no-repeat center center fixed;
    background-size: cover;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: flex-start;
    position: relative;
    width: 100%;

    header {
      flex-direction: column;
      align-items: center;

      a {
        text-align: center;
        margin-bottom: 10px;
      }

      img {
        margin: 5px 0 20px;
        width: 96%;

        @media (min-width: 501px) {
          margin: 5px 0 0;
        }

        @media (min-width: 701px) {
          width: 98%;
        }
      }

      .menu-btn {
        margin-right: 0;
        padding: 0;
        width: 35px;
        height: 35px;

        img {
          margin: 0;
          width: 35px;
          height: 35px;
        }
      }
    }

    .navigation {
      align-items: center;
      background-color: fade-out($black, .2);
      padding-top: 135px;

      @media (min-width: 501px) {
        padding-top: 160px;
      }

      @media (min-width: 701px) {
        padding-top: 180px;
      }

      @media (min-width: 901px) {
        padding-top: 205px;
      }

      @media (min-width: 1201px) {
        padding-top: 250px;
      }
    }

    .title {
      color: $white;
      font-size: 10px;
      letter-spacing: 3px;
      margin-top: 30vh;
      position: absolute;

      @media (min-width: 1201px) {
        margin-top: 35vh;
      }
    }

    .date {
      margin: auto;
      text-align: center;
    }

    .date-line {
      display: flex;
      flex-direction: column;

      p:first-child {
        font-size: 20px;
      }
    }

    .clock {
      color: $white;
      font-size: 16px;
      font-style: italic;
      font-weight: 100;
      letter-spacing: 10px;
      padding-top: 20px;
      position: relative;
    }
  }

  .section-top {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    padding-top: 300px;

    @media (min-width: 1201px) {
      align-items: flex-start;
      flex-direction: row;
      justify-content: center;
    }
  }

  .section-bottom {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;

    @media (min-width: 1201px) {
      flex-direction: row;
      justify-content: center;
    }

    .surf-info {
      margin-top: 50px;
    }

    .column {
      width: 550px;
    }

    p {
      font-family: 'Ruluko', sans-serif;
      font-size: 17px;
      padding-bottom: 12px;
    }
  }

  .section-title {
    width: 600px;
    // margin: 0 13px 0 0;

    @media (min-width: 1201px) {
      width: 550px;
    }

    @media (min-width: 1501px) {
      width: 600px;
    }

    h1 {
      font-family: 'Patua One', cursive;
      font-size: 100px;
      margin: 100px 0 0 0;

      @media (min-width: 1201px) {
        margin: -15px 0 0 0;
      }
    }

    h2 {
      font-family: 'Gill-sans', serif;
      font-size: 36px;
      font-style: italic;
      font-weight: 100;
      line-height: 45px;
      padding: 30px 150px 0 50px;
    }
  }

  .homepage-section-img {
    width: 600px;
    // margin: 0 50px;

    @media (min-width: 1201px) {
      width: 550px;
      margin-right: 13px;
    }

    @media (min-width: 1501px) {
      width: 600px;
      margin-right: 25px;
    }
  }

  .surf {
    background-color: #FFCD05;
    height: 2000px;

    @media (min-width: 1201px) {
      height: 1400px
    }

    img {
      max-width: 600px;
    }

    // p {
    //   padding-bottom: 15px;
    // }

    .section-top,
    .section-bottom {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      // justify-content: center;
      padding-top: 0;

      @media (min-width: 1201px) {
        flex-direction: row;
        justify-content: center;
      }
    }
  }

  .surf-title {
    font-family: 'Patua One', cursive;
    margin: 100px 0 0 0;
    width: 330px;
    height: 450px;
    position: relative;

    @media (min-width: 501px) {
      width: 450px;
      // height: 413px;
    }

    @media (min-width: 701px) {
      width: 600px;
      height: 450px;
    }

    @media (min-width: 1201px) {
      margin: 340px 13px 0 0;
      width: 550px;
      height: 413px;
    }

    @media (min-width: 1501px) {
      margin: 340px 25px 0 0;
      width: 600px;
      height: 450px;
    }

    h1 {
      font-size: 100px;
    }

    h2 {
      font-size: 50px;
    }

    .typo-rule {
      background-color: $black;
      position: absolute;
      height: 30px;
      width: 200px;
      top: 501px;

      @media (min-width: 501px) {
        top: 376px;
      }
    }
  }

  .the-a {
    position: absolute;
    top: -30px;
    left: 5px;
  }

  .history {
    position: absolute;
    top: 37px;
    left: 0;
  }

  .of {
    position: absolute;
    top: 130px;
    left: 0;

    @media (min-width: 501px) {
      top: 62px;
      left: 357px;
    }
  }

  .surfing {
    position: absolute;
    top: 197px;
    left: 0;

    @media (min-width: 501px) {
      top: 137px;
      left: 0;
    }
  }

  .in {
    position: absolute;
    top: 291px;
    left: 0;

    @media (min-width: 501px) {
      top: 157px;
      left: 368px;
    }
  }

  .sydney {
    position: absolute;
    top: 360px;
    left: 0;

    @media (min-width: 501px) {
      top: 237px;
      left: 0;
    }
  }

  .tracks-parent {
    width: 330px;

    @media (min-width: 501px) {
      width: 450px;
    }

    @media (min-width: 701px) {
      width: 600px;
    }

    @media (min-width: 1201px) {
      width: 550px;
    }

    @media (min-width: 1501px) {
      width: 600px;
    }

    p {
      font-family: 'Ruluko', sans-serif;
      font-size: 13px;
      margin: 15px 0 0 0;
    }

    span {
      font-family: 'Patua One', cursive;
    }
  }

  .tracks-cover {
    position: relative;
    margin: 300px 0 0 0;
    width: 100%;
    height: 250px;

    @media (min-width: 501px) {
      height: 335px;
    }

    @media (min-width: 701px) {
      margin: 375px 0 0 0;
      // width: 600px;
      height: 450px;
    }

    @media (min-width: 1201px) {
      // width: 550px;
      height: 413px;
    }

    @media (min-width: 1501px) {
      // width: 600px;
      height: 450px;
    }
  }

  .tracks {
    position: absolute;
    top: -1821px;
  }

  .surf-image {
    background: url('/homepage/surfing.jpg') no-repeat center center;
    background-size: cover;
    height: 100vh;
  }

  .surf-info {
    margin-top: 100px;
    width: 600px;

    @media (min-width: 1201px) {
      width: 1105px;
    }

    @media (min-width: 1501px) {
      width: 1220px;
    }
  }

  .surf-column {
    width: 550px;
  }

  .ratrods {
    color: #fff;
    background-color: #2B2A29;
    height: 1900px;
  }

  .ratrod-image {
    background: url('/homepage/rat-rod.jpg') no-repeat center center;
    background-size: cover;
    height: 100vh;
  }
}
