.nasa {
  background-color: #000;
  min-height: min-content;
  padding: 0 0 60px;

  h1 {
    color: $grey;
    font-size: 42px;
    margin: 50px 0 25px;
  }

  p {
    margin: 0 auto;
    max-width: 1300px;
  }

  .nasa-info {
    padding: 0 20px;

    @media (min-width: 901px) {
      padding: 0 50px;
    }
  }

  .media-image {
    position: relative;

    img {
      max-height: 80vh;
      max-width: 100%;
      position: relative;
      width: auto;
      z-index: 1;
    }
  }

  .media-video {
    height: 180px;
    margin: 0 auto;
    position: relative;
    width: 320px;

    @media (min-width: 501px) {
      height: 281px;
      width: 500px;
    }

    @media (min-width: 901px) {
      height: 422px;
      width: 750px;
    }

    @media (min-width: 1201px) {
      height: 562px;
      width: 1000px;
    }

    .video {
      position: relative;
      z-index: 1;
    }
  }
}

.asteroids {
  background-color: #000;
  min-height: min-content;
  padding: 0 5px;

  @media (min-width: 501px) {
    padding: 0 20px;
  }

  h1 {
    color: $grey;
    font-size: 42px;
    margin: 30px 0 25px;

    @media (min-width: 701px) {
      margin-top: 50px;
    }
  }

  section {
    margin-bottom: 20px;

    @media (min-width: 701px) {
      margin-bottom: 50px;
    }
  }

  .asteroid-belt {
    h1 {
      color: $white;
      margin-top: 50px;
    }

    p {
      padding: 0 15px;
    }
  }

  .asteroid-sizes,
  .asteroid-speeds {
    h1 {
      color: $grey;
      font-style: italic;
    }
  }

  .asteroid-sizes {
    ul {
      margin-top: 50px;
    }

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 0 50px;
    }

    h2,
    a {
      color: $grey;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 1px;
      margin: 18px 0 9px;
      text-decoration: none;
    }

    .circle {
      background-color: #999;
      border-radius: 50%;
      height: 30px;
      width: 30px;
      max-height: 320px;
      max-width: 320px;

      @media (min-width: 375px) {
        max-height: 373px;
        max-width: 373px;
      }

      @media (min-width: 501px) {
        max-height: 499px;
        max-width: 499px;
      }

      @media (min-width: 701px) {
        max-height: 699px;
        max-width: 699px;
      }

      @media (min-width: 901px) {
        max-height: none;
        max-width: none;
      }
    }
  }

  .asteroid-speeds {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;

    h1 {
      margin-top: 10px;
    }

    ul {
      margin: 20px 0 60px;
      max-width: 1000px;
      width: 100%;

      li {
        align-items: flex-start;
        background-color: #f78102;
        border-radius: 0 0 45px 0;
        display: flex;
        flex-direction: column;
        height: 20px;
        margin-bottom: 21px;
        max-width: 100%;
        padding: 5px 0;
        width: 100%;

        @media (min-width: 501px) {
          align-items: center;
          flex-direction: row;
          height: 30px;
          margin-bottom: 25px;
        }

        &.asteroid-speed-earth {
          background-color: $earth;
        }

        h2 {
          color: #fff;
          font-size: 16px;
          margin: 2px 0 0 10px;
          white-space: nowrap;

          @media (min-width: 501px) {
            font-size: 18px;
            margin: 12px 0 12px 10px;
          }
        }

        p {
          color: #fff;
          font-size: 11px;
          font-style: italic;
          letter-spacing: 1px;
          margin: 0 0 0 10px;
          white-space: nowrap;

          @media (min-width: 501px) {
            font-size: 12px;
            margin-top: 3px;
          }
        }
      }

      .asteroid-speed-earth {
        background-image: linear-gradient(to right, #000, #05c0b9);
      }

      .asteroid-speed-iss {
        background-image: linear-gradient(to right, #000, #828bee);
        width: 17%;
      }

      .asteroid-speed-sound {
        background-image: linear-gradient(to right, #000, #f78102);
        width: 1%;
      }
    }
  }
}
