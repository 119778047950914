// body {
//   -webkit-font-smoothing: antialiased;
// }

h2 {
  font-size: 18px;
  letter-spacing: 1px;
  margin: 12px 0;
}

p {
  font-size: 16px;
  font-weight: 100;
  letter-spacing: .4px;
  line-height: 1.2;
}

.danger {
  color: red;
}

.bold {
  font-weight: bold;
}
