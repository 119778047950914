.content {
  align-items: center;
  background-color: $white;
  color: $grey;
  display: flex;
  min-height: 100vh;
  padding: 0 20px;
  flex-direction: column;
  text-align: center;

  h1 {
    font-size: 40px;
    margin: 50px 0 20px;

    @media (min-width: 701px) {
      font-size: 50px;
    }
  }
}

.inventory {
  form {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 500px;
  }

  input {
    border: 1px solid $grey;
    margin-bottom: 15px;
    padding: 15px 10px 15px 13px;
    width: 100%;

    &::placeholder {
      color: $grey-pale
    }
  }

  select {
    border: 1px solid $grey;
    border-radius: 0;
    height: 45px;
    margin-bottom: 15px;
    width: 100%;
  }

  textarea {
    margin-bottom: 15px;
    padding: 15px 10px 15px 13px;
    width: 100%;
  }
}

.wip {
  border: 10px solid red;

  h3 {
    color: red;
    font-size: 20px;
    font-weight: 100;
    letter-spacing: 2px;
    margin: 30px 60px;
  }
}

li {
  &.wip-link {
    &:before {
      background: red;
    }

    a {
      color: red;
    }
  }
}
