body {
  background-color: #fff;
  font-family: Gill Sans;
  margin: 0;
}

header {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  z-index: 3;

  img {
    width: 150px;
    margin: 14px 16px 0;

    @media (min-width: 701px) {
      margin: 16px 30px 0;
    }
  }
}

.navigation {
  align-items: center;
  background-color: fade-out($black, .1);
  display: flex;
  flex-direction: column;
  padding: 100px 0 100px;
  position: fixed;
  right: 0px;
  top: 0;
  transform: translateY(-1160px);
  transition: transform 1s ease;
  width: 100%;
  z-index: 2;

  li {
    align-items: center;
    display: flex;
    justify-content: center;
    list-style-type: none;
    margin-bottom: 23px;
    padding-bottom: 3px;
    position: relative;
    width: 225px;

    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      left: 51%;
      right: 51%;
      bottom: -3px;
      background: $grey;
      height: 1px;
      transition-property: left, right;
      transition-duration: .3s;
      transition-timing-function: ease-out;
    }

    &:hover {
      cursor: pointer;

      &:before {
        left: 0;
        right: 0;
      }
    }
  }

  a {
    color: #fff;
    font-weight: 100;
    letter-spacing: 2px;
    text-decoration: none;
  }

  &.open {
    transform: translateY(0);
    transition: transform 1s ease;
  }
}

button {
  background-color: $grey-pale;
  border-radius: 50%;
  color: $white;
  font-size: 9px;
  letter-spacing: 2px;
  height: 75px;
  padding: 0 0 4px;
  text-transform: uppercase;
  width: 75px;

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }
}

img {
  width: 100%;
}

.menu-btn {
  cursor: pointer;
  height: 25px;
  width: 25px;
  margin-right: 5px;
  padding: 8px;
  transform: rotate(45deg);
  transition: transform 1s ease;

  @media (min-width: 501px) {
    height: 30px;
    width: 30px;
    margin-right: 10px;
    padding: 10px;
  }

  img {
    height: 25px;
    width: 25px;
    margin: 0;

    @media (min-width: 501px) {
      height: 30px;
      width: 30px;
    }
  }

  &.open {
    transform: rotate(180deg);
    transition: transform 1s ease;
  }
}

input {
  border: 1px solid #999;
  height: 40px;
  margin-top: 30px;
  padding: 0 0 0 20px;
  width: 300px;
}

.video {
  height: 180px;
  width: 320px;
  margin: 0 auto;

  @media (min-width: 375px) {
    height: 197px;
    width: 350px;
  }

  @media (min-width: 501px) {
    height: 281px;
    width: 499px;
  }

  @media (min-width: 701px) {
    height: 393px;
    width: 699px;
  }

  @media (min-width: 901px) {
    height: 506px;
    width: 899px;
  }

  @media (min-width: 1201px) {
    height: 674px;
    width: 1199px;
  }
}

.red {
  color: red;
}

.hide {
  display: none;
}

.search {
  input {
    padding: 0 0 2px 15px;
  }
}

.search-button {
  background-color: transparent;
  height: 30px;
  width: 30px;
  position: absolute;
  right: 10px;
  top: 7px;
}
