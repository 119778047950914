.randomuser {
  margin: 80px 50px 0;
  max-width: 350px;
  position: relative;

  @media (min-width: 601px) {
    max-width: 400px;
  }

  @media (min-width: 901px) {
    max-width: 500px;
  }

  img {

  }

  h3 {
    font-size: 12px;
    margin: 17px 0 10px;

    @media (min-width: 601px) {
      font-size: 14px;
    }

    @media (min-width: 901px) {
      font-size: 16px;
    }
  }

  .info-container {
    display: flex;
    justify-content: center;

    p {
      font-size: 12px;
      font-weight: 100;
      letter-spacing: 1px;
      margin-right: 5px;

      @media (min-width: 601px) {
        font-size: 14px;
      }

      @media (min-width: 901px) {
        font-size: 16px;
      }
    }

    &:nth-child(5) {
      flex-direction: column;

      p {
        padding-bottom: 3px;
      }

      @media (min-width: 601px) {
        flex-direction: row;

        p {
          padding-bottom: 0;
        }
      }
    }
  }

  div:last-child {
    color: green;
  }
}

.randomuser-btn {
  margin-top: 30px;
  margin-bottom: 50px;
  padding: 0 10px 4px;

  &:hover {
    background-color: #c2c2c2;
  }
}

.randomuser-country {
  font-family: 'Cormorant Garamond', serif;
  position: relative;
  width: 100%;

  h1 {
    font-weight: 100;
    margin-top: 80px;
  }

  h3 {
    font-size: 20px;
    margin: 5px 0 2px;
  }

  form {
    align-items: center;
    display: flex;
    flex-direction: column;

    .input-container {
      align-items: center;
      display: flex;
      flex-direction: row;
      margin: 15px 0 30px;
      max-width: 360px;
      position: relative;
      width: 100%;

      @media (min-width: 901px) {
        max-width: 400px;
      }
    }

    input {
      font-family: 'Cormorant Garamond', serif;
      font-weight: 400;
      font-size: 28px;
      letter-spacing: 1px;
      margin: 0;
      padding-bottom: 3px;
      width: 100%;
    }

    ::placeholder {
      color: $grey-pale;
    }
  }

  ul {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 30px 20px 30px;
  }

  li {
    list-style: none;
    margin: 0 20px 40px;
    width: 230px;

    img {
      border-radius: 50%;
      width: 140px;
    }
  }

  .search-field {
    display: flex;
    flex-direction: column;
    position: relative;

    .country-list {
      font-family: 'Cormorant Garamond', serif;
      font-weight: 100;
      letter-spacing: .5px;
      margin-bottom: 15px;

      p {
        margin-bottom: 10px;
      }

      ul {
        display: flex;
        margin: 0;
      }

      li {
        list-style: none;
        margin: 0 6px 2px;
        width: auto;
      }
    }
  }
}

.flag-container {
  display: flex;
  justify-content: center;
}

.flag {
  height: 75px;
  width: 75px;
}
